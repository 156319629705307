import React, { useState, useEffect } from "react";
import "./Hostess.css";
import Logo from "../components/Logo";
import { useSelector } from "react-redux";
import HostessTableModal from "../components/HostessTableModal"; // Import your modal component here
import Loader from "../components/Loader";
import { socket } from "../App";

export default function Hostess() {
  const tablesState = useSelector((state) => state.tables); // Fetch tables directly from Redux store
  const [tables, setTables] = useState(tablesState);
  const [selectedTable, setSelectedTable] = useState(null); // State to store selected table for modal

  // Function to open modal with selected table
  const handleTableClick = (table) => {
    setSelectedTable(table);
  };

  // Function to close modal
  const closeModal = () => {
    setSelectedTable(null);
  };

  useEffect(() => {
    setTables(tablesState);

    socket.on("tableUpdated", (updatedData) => {
      setTables(updatedData); // Update table data in frontend
    });

    // Disconnect from Socket.IO when not needed
    return () => {
      socket.off("tableUpdated");
    };
  }, [tablesState]);

  if (tablesState.loading) {
    return <Loader />;
  }

  return (
    <div className="hostess-cnt">
      <Logo style={{ height: "200px", width: "230px" }} />

      {/* Table status legend */}
      <div className="table-status-section">
        <div className="table-status">
          <div className="status-box available"></div>
          <span>פנוי</span>
        </div>
        <div className="table-status">
          <div className="status-box occupied"></div>
          <span>תפוס</span>
        </div>
        <div className="table-status">
          <div className="status-box reserved"></div>
          <span>שמור</span>
        </div>
      </div>

      {/* Table layout */}
      <div className="table-canvas-container">
        {tables.length > 0
          ? tables.map((table) => (
              <div
                className="hostess-table"
                key={table.id}
                style={{
                  backgroundColor:
                    table.status === "available"
                      ? "#00e50a"
                      : table.status === "occupied"
                      ? "#ff2b1b"
                      : table.status === "reserved"
                      ? "orange"
                      : "grey",
                }}
                onClick={() => handleTableClick(table)} // Add onClick to open modal
              >
                <span className="table-number">שולחן {table.number}</span>
                <span className="table-capacity">{table.capacity} סועדים</span>
              </div>
            ))
          : "הוסף שולחנות לעסק"}
      </div>

      {/* Modal for table details */}
      {selectedTable && (
        <HostessTableModal table={selectedTable} onClose={closeModal} />
      )}
    </div>
  );
}
