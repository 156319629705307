import React, { useEffect, useState } from "react";
import { login, workerLogin } from "../actions/businessAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Logo from "../components/Logo";
import blueVelvet from "../uploads/dark-wood.jpg";
import BackgroundImage from "../components/BackgroundImage";
import "./login.css";

export default function BusinessLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const worker = useSelector((state) => state.worker);

  const { business } = useParams();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(workerLogin(business, userName, password));
  };

  useEffect(() => {
    if (worker && worker.token && worker.info.role) {
      switch (worker.info.role) {
        case "מנהל": // Manager role
          navigate("/manager"); // replace with actual path for manager
          break;
        case "מלצר": // Waiter role
          navigate("/waiter"); // replace with actual path for waiter
          break;
        case "שף": // Chef role
          navigate("/chef"); // replace with actual path for chef
          break;
        case "מארחת": // Hostess role
          navigate("/hostess"); // replace with actual path for hostess
          break;
        default:
          navigate("/"); // Default path if role is undefined or unexpected
          break;
      }
    }
  }, [navigate, worker]);

  return (
    <div className="login-cnt">
      <BackgroundImage img={blueVelvet} />
      <Logo />
      <div className="login-tab">
        <h1>התחברות לדף העסק</h1>
        <h2>שם משתמש</h2>
        <input
          placeholder="הזן כאן"
          onChange={(e) => setUserName(e.target.value)}
        />
        <h2>סיסמה</h2>
        <input
          type="password"
          placeholder="הזן כאן"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          style={{ width: "100px", height: "30px" }}
          onClick={(e) => submitHandler(e)}
        >
          היכנס!
        </button>
      </div>
    </div>
  );
}
