import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./settings.css";
import Loader from "../components/Loader";
import TablesManager from "../components/settings/TablesManager";
import GeneralSettings from "../components/settings/GeneralSettings";
import MenuProManager from "../components/MenuProManager";
import Members from "../components/Members";
import { getMenu } from "../actions/userActions";
import { BiBookOpen } from "react-icons/bi";
import RolesManager from "../components/RolesManager";
import Dashboard from "../components/Dashboard";
import RestaurantSettings from "../components/RestaurantSettings";
import { getWorkers } from "../actions/businessAction";

export default function SettingsPro() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const business = useSelector((state) => state.business);
  const { info } = business;

  const menu = useSelector((state) => state.menu);
  const { loading } = menu;

  const style = useSelector((state) => state.style);
  const { background, parentButton, font, childButton } = style;

  const [type, setType] = useState("menu");

  const [greeting, setGreeting] = useState("שלום");

  const renderActiveComponent = () => {
    switch (type) {
      case "menu":
        return <MenuProManager style={style} />;
      case "tables":
        return <TablesManager />;
      case "fans":
        return <Members />;
      case "Roles":
        return <RolesManager />;
      case "general":
        return <GeneralSettings />;
      case "style":
        return <TablesManager />;
      case "settings":
        return <RestaurantSettings />;
      case "dashboard":
        return <Dashboard />;

      default:
        return <TablesManager />;
    }
  };

  useEffect(() => {
    if (business && info) {
    } else {
      navigate("/login");
    }

    dispatch(getMenu(info.name));
    dispatch(getWorkers());

    const getCurrentTime = () => {
      const currentHour = new Date().getHours();

      if (currentHour >= 5 && currentHour < 12) {
        setGreeting("בוקר טוב");
      } else if (currentHour >= 12 && currentHour < 18) {
        setGreeting("צהריים טובים");
      } else {
        setGreeting("ערב טוב");
      }
    };

    getCurrentTime();
    const interval = setInterval(getCurrentTime, 60000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      style={{
        background: background.color,
        minHeight: "100vh",
        ...font,
        paddingBottom: "20px",
      }}
    >
      <BiBookOpen
        className="home-btn"
        style={{
          background: parentButton.style.background,
          color: parentButton.style.color,
        }}
        onClick={() => navigate(`/${info.name}/menu2`)}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <h1 style={{ padding: "10px" }}>{greeting}</h1>
        <h1 style={{ padding: "10px" }}>{info.name}</h1>
      </div>
      <div className="settings-btn-cnt">
        <button style={parentButton.style} onClick={() => setType("dashboard")}>
          ראשי
        </button>
        <button style={parentButton.style} onClick={() => setType("menu")}>
          הגדרות תפריט
        </button>

        <button style={parentButton.style} onClick={() => setType("tables")}>
          שולחנות
        </button>
        <button style={parentButton.style} onClick={() => setType("Roles")}>
          הגדרת תפקידים
        </button>
        <button style={parentButton.style} onClick={() => setType("tables")}>
          עיצוב
        </button>
        <button style={parentButton.style} onClick={() => setType("fans")}>
          חברי מועדון
        </button>
        <button
          style={parentButton.style}
          onClick={() => navigate(`/${info.name}/menu2`)}
        >
          לתפריט
        </button>
        <button style={parentButton.style} onClick={() => setType("settings")}>
          הגדרות
        </button>
      </div>
      {renderActiveComponent()}
    </div>
  );
}
