import React, { useState } from "react";
import "./HostessTableModal.css";
import { useDispatch } from "react-redux";
import { hostessUpdateTable } from "../actions/businessAction";

export default function HostessTableModal({ table, onClose }) {
  const dispatch = useDispatch();

  const [updatedTable, setUpdatedTable] = useState({
    ...table, // Initialize with the current table's values
  });

  const handleSave = () => {
    dispatch(hostessUpdateTable(updatedTable));
    onClose(); // Close the modal after saving
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUpdatedTable({
      ...updatedTable,
      [id]: value, // Update the value based on the field
    });
  };

  return (
    <div className="hostess-table-modal-overlay" onClick={onClose}>
      <div className="hostess-table-modal" onClick={(e) => e.stopPropagation()}>
        <h2>פרטי שולחן {updatedTable.number}</h2>
        <label htmlFor="status">
          כמות סועדים מקסימלית: {updatedTable.capacity}
        </label>

        <div className="table-details">
          <label htmlFor="guests">כמות סועדים:</label>
          <input
            id="guestsCount"
            type="number"
            value={updatedTable.guestsCount}
            onChange={handleInputChange}
            rows="3"
          />
          {/* Status Dropdown */}
          <label htmlFor="status">סטטוס שולחן:</label>
          <select
            id="status"
            value={updatedTable.status}
            onChange={handleInputChange}
            style={{ height: "45px" }}
          >
            <option value="available">פנוי</option>
            <option value="occupied">תפוס</option>
            <option value="reserved">שמור</option>
          </select>
          <div className="allergies-section">
            <label>אלרגיות?</label>
            <textarea
              id="allergies"
              value={updatedTable.allergies || ""}
              onChange={handleInputChange}
              style={{ height: "45px" }}
            />
          </div>
          {/* Notes Section */}
          <label htmlFor="notes">הערות:</label>
          <textarea
            id="notes"
            value={updatedTable.notes || "אין הערות"}
            onChange={handleInputChange}
            rows="3"
            style={{ height: "45px" }}
          />
        </div>

        <button onClick={handleSave}>עדכון שולחן</button>
      </div>
    </div>
  );
}
