import React, { useState } from "react";
import "./HostessTableModal.css";
import "./WaiterTableModal.css";
import { useDispatch } from "react-redux";
import { acceptGuest, hostessUpdateTable } from "../actions/businessAction";
import { IoMdPerson } from "react-icons/io";

export default function HostessTableModal({ table, onClose }) {
  const dispatch = useDispatch();

  const [updatedTable, setUpdatedTable] = useState({
    ...table, // Initialize with the current table's values
  });

  const handleSave = () => {
    dispatch(hostessUpdateTable(updatedTable));
    onClose(); // Close the modal after saving
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUpdatedTable({
      ...updatedTable,
      [id]: value, // Update the value based on the field
    });
  };

  return (
    <div className="waiter-table-modal-overlay" onClick={onClose}>
      <div className="hostess-table-modal" onClick={(e) => e.stopPropagation()}>
        <h2>פרטי שולחן {updatedTable.number}</h2>
        {table.allergies ? (
          <label htmlFor="status">אלרגיות: {table.allergies}</label>
        ) : (
          ""
        )}
        <div>
          {table.guests.length > 0 ? (
            <div>
              <h3>אישור אורחים</h3>
              <div style={{ height: "250px" }}>
                {table.guests.map((guest) => (
                  <div className="waiter-accept-guests" key={guest.phone}>
                    <p>{guest.name}</p>
                    <button onClick={() => dispatch(acceptGuest(guest, table))}>
                      אישור
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div>
          {table.guests.length > 0 ? (
            <div style={{ marginTop: "20px" }}>
              <h3>אורחים</h3>

              <div className="waiter-guests-cnt">
                {table.guests.map((guest) => (
                  <div className="waiter-guests" key={guest.phone}>
                    <IoMdPerson />
                    <span>{guest.name}</span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="table-details">
          <label htmlFor="guests">כמות סועדים:</label>
          <input
            id="guestsCount"
            type="number"
            value={updatedTable.guestsCount}
            onChange={handleInputChange}
            rows="3"
          />
          {/* Status Dropdown */}
          <label htmlFor="status">סטטוס שולחן:</label>
          <select
            id="status"
            value={updatedTable.status}
            onChange={handleInputChange}
            style={{ height: "45px" }}
          >
            <option value="available">פנוי</option>
            <option value="occupied">תפוס</option>
            <option value="reserved">שמור</option>
          </select>

          {/* Notes Section */}
          <label htmlFor="notes">הערות:</label>
          <textarea
            id="notes"
            value={updatedTable.notes || "אין הערות"}
            onChange={handleInputChange}
            rows="3"
            style={{ height: "45px" }}
          />
        </div>

        <button onClick={handleSave}>עדכון שולחן</button>
      </div>
    </div>
  );
}
