import "./Checkout.css";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BsFillDashCircleFill } from "react-icons/bs"; // Import the minus icon

function Checkout() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [extraRequest, setExtraRequest] = useState(""); // State to store extra request from customer

  const { tableNumber } = useParams();

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(storedCart);

    const total = storedCart.reduce(
      (acc, item) => acc + parseFloat(item.price) * item.quantity,
      0
    );
    setTotalAmount(total);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ name, email, address, paymentMethod, extraRequest });
  };

  const handleRemoveItem = (index) => {
    const updatedCart = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCart);
    const total = updatedCart.reduce(
      (acc, item) => acc + parseFloat(item.price) * item.quantity,
      0
    );
    setTotalAmount(total);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  return (
    <div className="checkout-container">
      <h1 className="checkout-title">
        {tableNumber ? `הזמנה לשולחן ${tableNumber}` : "ההזמנה שלך"}
      </h1>
      <form onSubmit={handleSubmit} className="checkout-form">
        {/* Order Summary */}
        <div className="order-summary">
          <h2 style={{ padding: "10px 15px" }}>סיכום הזמנה</h2>
          <table className="summary-table">
            <thead>
              <tr>
                <th>מוצר</th>
                <th>מחיר</th>
                <th>כמות</th>
                <th>סה"כ</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item, index) => (
                <>
                  <BsFillDashCircleFill
                    onClick={() => handleRemoveItem(index)}
                    style={{
                      cursor: "pointer",
                      color: "red",
                      marginTop: "15px",
                      position: "absolute",
                      right: "6px",
                    }}
                  />
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{item.price * item.quantity}</td>
                  </tr>
                </>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2">סה"כ לתשלום</td>
                <td colSpan="2" style={{ textAlign: "left" }}>
                  {totalAmount} ₪
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        {/* Extra Request from Customer */}
        <div className="input-group">
          <label htmlFor="extraRequest">הערות / בקשות מיוחדות</label>
          <textarea
            id="extraRequest"
            value={extraRequest}
            onChange={(e) => setExtraRequest(e.target.value)}
            placeholder="הוסף הערות כאן..."
          />
        </div>

        <button type="submit" className="checkout-button">
          אישור הזמנה
        </button>
      </form>
    </div>
  );
}

export default Checkout;
