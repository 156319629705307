import React, { useState, useEffect } from "react";
import "./CartMenu.css";
import { BsFillXCircleFill } from "react-icons/bs";
import { FaMinusCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { BiCart } from "react-icons/bi";

const useQuery = () => new URLSearchParams(useLocation().search);

function CartsMenu() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const query = useQuery();
  const tableNumber = query.get("tableNumber");

  const toggleCart = () => {
    // Update cart items from local storage each time the cart is opened
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    const updatedCart = storedCart.map((item) => ({
      ...item,
      price: parseFloat(item.price), // Convert price to number
    }));
    setCartItems(updatedCart);
    setIsOpen(!isOpen);
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  const calculateTotal = () => {
    return cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  };

  const handleRemoveItem = (index) => {
    const confirmed = window.confirm(
      "האם אתה בטוח שברצונך למחוק את הפריט מהעגלה?"
    );
    if (confirmed) {
      const updatedCart = cartItems.filter((_, i) => i !== index);
      setCartItems(updatedCart);
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    }
  };

  return (
    <div className="navigate-btn-cnt">
      <button className="cart-button" onClick={toggleCart}>
        <BiCart />
      </button>

      {isOpen && (
        <>
          <div className="overlay" onClick={handleOverlayClick}></div>
          <div className="cart-menu">
            <div className="cart-content">
              <div className="cart-item">
                <h2>ההזמנה שלך</h2>
                <BsFillXCircleFill
                  className="close-btn"
                  onClick={handleOverlayClick}
                />
              </div>

              {/* Table for cart items */}
              <div style={{ maxHeight: "375px" }}>
                <table className="cart-table">
                  <thead>
                    <tr>
                      <th>שם</th>
                      <th>כמות</th>
                      <th>מחיר</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item, index) => (
                      <>
                        <tr key={index}>
                          <td style={{ display: "flex", paddingRight: "0" }}>
                            <FaMinusCircle
                              className="remove-icon"
                              onClick={() => handleRemoveItem(index)}
                              style={{
                                cursor: "pointer",
                                color: "red",
                                marginLeft: "10px",
                              }}
                            />{" "}
                            {item.name}
                          </td>
                          <td>{item.quantity}</td>
                          <td>{item.price}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Total Calculation */}
              <div className="cart-total">
                <h3>סה"כ</h3>
                <h3>{calculateTotal().toFixed(2)} </h3>
              </div>
            </div>
            <button
              className="cart-order-button"
              onClick={() => {
                if (tableNumber) {
                  navigate(`/checkout/${tableNumber}`);
                } else {
                  navigate("/checkout");
                }
              }}
            >
              {tableNumber
                ? `לביצוע הזמנה לשולחן ${tableNumber}`
                : "לביצוע הזמנה"}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default CartsMenu;
