import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import SettingsPro from "./screens/SettingsPro";
import Login from "./screens/LoginScreen";
import RegisterBusinessScreen from "./screens/RegisterBusinessScreen";
import CoffeeDizengoff from "./screens/CoffeeDizengoff";
import Trail from "./screens/Trail";
import Loading from "./screens/Loading";
import Menu from "./screens/Menu";
import Settings from "./screens/Settings";
import Accessibility from "./components/Accessibility";
import Alma from "./screens/alma/Alma";
import AlmaPhone from "./screens/alma/AlmaPhone";
import RestaurantHome from "./screens/ResturentHome";
import Menu2 from "./screens/Menu2";
import Menu3 from "./screens/Menu3";
import Landing from "./screens/Loading";
import Checkout from "./screens/Checkout";
import BusinessLogin from "./screens/BusinessLogin";
import Hostess from "./screens/Hostess";
import io from "socket.io-client";
import Waiter from "./screens/Waiter";

function App() {
  return (
    <div className="App">
      <Router>
        <Accessibility />
        <main>
          <Routes>
            <Route
              path="/register-business"
              element={<RegisterBusinessScreen />}
            />
            <Route path="/settings" element={<SettingsPro />} />
            {/* <Route path="/settings" element={<Settings />} /> */}
            <Route path="/:name/menu" element={<Menu />} />
            <Route path={`/coffee-caramel`} element={<RestaurantHome />} />
            <Route path={`/:name/menu2`} element={<Menu2 />} />
            <Route path={`/:name/menu3`} element={<Menu3 />} />
            <Route path={`/coffee`} element={<CoffeeDizengoff />} />
            <Route path="/login" element={<Login />} />
            <Route path="/:business/login" element={<BusinessLogin />} />
            <Route path="/trail" element={<Trail />} />
            <Route path="/alma" element={<Alma />} />
            <Route path="/alma/phone" element={<AlmaPhone />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/hostess" element={<Hostess />} />
            <Route path="/waiter" element={<Waiter />} />
            <Route path="/checkout/:tableNumber" element={<Checkout />} />
            <Route path="/" element={<HomeScreen />} />
          </Routes>
        </main>
      </Router>
    </div>
  );
}

export default App;
export const socket = io(process.env.REACT_APP_BASE_URL);
