import React, { useState, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";
import { IoEyeOff, IoEye } from "react-icons/io5";
import { FaEdit, FaMinusCircle } from "react-icons/fa";
import {
  BsCheckCircleFill,
  BsFillXCircleFill,
  BsPlusCircleFill,
} from "react-icons/bs";
import { HiMinusCircle, HiPlusCircle } from "react-icons/hi";

import "./meals.css";
import "react-toastify/dist/ReactToastify.css";

export default function Meal({ meal, style, auth, index, available, setMeal }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const [mealObj, setMealObj] = useState(meal);
  const [quantity, setQuantity] = useState(1); // State to manage quantity

  const springs = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 700 },
  });

  const modalSpring = useSpring({
    transform: isModalOpen ? "translateY(0%)" : "translateY(100%)",
    opacity: isModalOpen ? 1 : 0,
    config: { duration: 500 },
  });

  const handleContainerClick = () => {
    // Fetch current quantity from local storage when modal opens
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItem = cart.find((item) => item.name === mealObj.name);
    if (existingItem) {
      setQuantity(existingItem.quantity); // Update quantity state from local storage
    } else {
      setQuantity(1); // Reset to 1 if item not in cart
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleEditCheck = () => {
    setEditable(false);
    setMeal(mealObj, index); // Update meal details in the parent component
  };

  const renderEditButtons = () => {
    if (auth) {
      return (
        <div className="edit-cnt">
          {auth && meal.available ? (
            <IoEye onClick={() => available(index)} />
          ) : (
            <IoEyeOff onClick={() => available(index)} />
          )}
          <FaEdit onClick={() => setEditable(true)} />
          <BsCheckCircleFill onClick={handleEditCheck} />
        </div>
      );
    }
    return null;
  };

  const addToCart = () => {
    const cartItem = {
      name: mealObj.name,
      price: mealObj.price,
      quantity: quantity, // Use the current quantity from state
    };

    // Fetch existing cart from local storage
    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

    // Check if the item already exists in the cart
    const existingItemIndex = existingCart.findIndex(
      (item) => item.name === cartItem.name
    );

    if (existingItemIndex !== -1) {
      // If it exists, update the quantity to the new value
      existingCart[existingItemIndex].quantity = quantity; // Update to new quantity
    } else {
      // If it doesn't exist, push the new item
      existingCart.push(cartItem);
    }

    // Save updated cart to local storage
    localStorage.setItem("cart", JSON.stringify(existingCart));

    //alert(`${mealObj.name} הוסף לעגלה!`); // Alert user that the item was added/updated
    closeModal(); // Close modal after adding to cart
  };

  return (
    <>
      <animated.div
        style={{ ...style, ...springs }}
        className="meal-cnt"
        onClick={handleContainerClick}
      >
        {renderEditButtons()}
        <div style={{ width: meal.img ? "230px" : "320px" }}>
          <div className="title-price">
            {editable ? (
              <>
                <input
                  style={{ width: "200px" }}
                  value={mealObj.name}
                  onChange={(e) =>
                    setMealObj({ ...mealObj, name: e.target.value })
                  }
                />
                <input
                  style={{
                    fontSize: "15px",
                    alignSelf: "center",
                    width: "75px",
                  }}
                  value={mealObj.price}
                  onChange={(e) =>
                    setMealObj({ ...mealObj, price: e.target.value })
                  }
                />
              </>
            ) : (
              <>
                <p style={{ width: "180px" }}>{mealObj.name}</p>
                <p style={{ fontSize: "15px", alignSelf: "center" }}>
                  {mealObj.price}
                </p>
              </>
            )}
          </div>
          {editable ? (
            <div
              style={{ width: mealObj.img ? "200px" : "220px" }}
              className="desc"
            >
              <textarea
                value={mealObj.desc}
                onChange={(e) =>
                  setMealObj({ ...mealObj, desc: e.target.value })
                }
              />
            </div>
          ) : (
            <div
              style={{ width: mealObj.img ? "200px" : "220px" }}
              className="desc"
            >
              {mealObj.desc}
            </div>
          )}
        </div>
        {mealObj.img && (
          <img
            src={mealObj.img}
            className="meals-img"
            onClick={handleContainerClick}
          />
        )}
      </animated.div>

      {isModalOpen && (
        <animated.div style={modalSpring} className="modal">
          <div className="modal-overlay" onClick={closeModal}></div>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            {mealObj.img && (
              <img src={mealObj.img} className="modal-img" alt="Meal" />
            )}
            <div className="modal-header">
              <h2>{mealObj.name}</h2>
              <BsFillXCircleFill onClick={closeModal} className="close-btn" />
            </div>
            <div className="modal-body">
              <p>{mealObj.desc}</p>
              <div className="menu-cart-cnt">
                <button onClick={addToCart} className="add-to-cart-btn">
                  הוסף לעגלה
                </button>
                <div className="quantity-controls">
                  <HiPlusCircle onClick={() => setQuantity(quantity + 1)} />
                  <input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(Math.max(1, e.target.value))}
                    min="1"
                  />
                  <HiMinusCircle
                    onClick={() => setQuantity(Math.max(1, quantity - 1))}
                  />
                </div>
              </div>
            </div>
          </div>
        </animated.div>
      )}
    </>
  );
}
