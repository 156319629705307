import { BUSINESS_LOGOUT } from "../constants/businessConstant.js";
import {
  TABLES_GET_LIST_REQUEST,
  TABLES_GET_LIST_SUCCESS,
  TABLES_GET_LIST_FAIL,
  TABLES_UPDATE_REQUEST,
  TABLES_UPDATE_SUCCESS,
  TABLES_UPDATE_FAIL,
  TABLES_OPEN_REQUEST,
  TABLES_OPEN_SUCCESS,
  TABLES_OPEN_FAIL,
  TABLES_CLOSE_REQUEST,
  TABLES_CLOSE_SUCCESS,
  TABLES_CLOSE_FAIL,
  HOSTESS_UPDATE_TABLE_SUCCESS,
  HOSTESS_UPDATE_TABLE_REQUEST,
} from "../constants/tablesConstant.js";

export const tablesReducer = (state = {}, action) => {
  switch (action.type) {
    // Get Tables
    case TABLES_GET_LIST_REQUEST:
      return { ...state, loading: true };
    case TABLES_GET_LIST_SUCCESS:
      return action.payload;
    case TABLES_GET_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    // Update a Table (e.g., updating table status)
    case TABLES_UPDATE_REQUEST:
      return { ...state, loadingUpdate: true };
    case TABLES_UPDATE_SUCCESS:
      return action.payload;
    case TABLES_UPDATE_FAIL:
      return { ...state, loadingUpdate: false, error: action.payload };

    // Open a Table
    case TABLES_OPEN_REQUEST:
      return { ...state, loadingOpen: true };
    case TABLES_OPEN_SUCCESS:
      return {
        ...state,
        loadingOpen: false,
        tables: state.tables.map((table) =>
          table.id === action.payload.id
            ? { ...table, status: "available" }
            : table
        ),
      };
    case TABLES_OPEN_FAIL:
      return { ...state, loadingOpen: false, error: action.payload };

    // Close a Table
    case TABLES_CLOSE_REQUEST:
      return { ...state, loadingClose: true };
    case TABLES_CLOSE_SUCCESS:
      return {
        ...state,
        loadingClose: false,
        tables: state.tables.map((table) =>
          table.id === action.payload.id
            ? { ...table, status: "reserved" }
            : table
        ),
      };
    case HOSTESS_UPDATE_TABLE_REQUEST:
      return { loading: true };
    case HOSTESS_UPDATE_TABLE_SUCCESS:
      return action.payload;

    case TABLES_CLOSE_FAIL:
      return { ...state, loadingClose: false, error: action.payload };
    case BUSINESS_LOGOUT:
      return {};

    default:
      return state;
  }
};
