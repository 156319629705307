// tableConstants.js
export const TABLES_GET_LIST_REQUEST = "TABLES_GET_LIST_REQUEST";
export const TABLES_GET_LIST_SUCCESS = "TABLES_GET_LIST_SUCCESS";
export const TABLES_GET_LIST_FAIL = "TABLES_GET_LIST_FAIL";

export const TABLES_UPDATE_REQUEST = "TABLES_UPDATE_REQUEST";
export const TABLES_UPDATE_SUCCESS = "TABLES_UPDATE_SUCCESS";
export const TABLES_UPDATE_FAIL = "TABLES_UPDATE_FAIL";

export const TABLES_OPEN_REQUEST = "TABLES_OPEN_REQUEST";
export const TABLES_OPEN_SUCCESS = "TABLES_OPEN_SUCCESS";
export const TABLES_OPEN_FAIL = "TABLES_OPEN_FAIL";

export const TABLES_CLOSE_REQUEST = "TABLES_CLOSE_REQUEST";
export const TABLES_CLOSE_SUCCESS = "TABLES_CLOSE_SUCCESS";
export const TABLES_CLOSE_FAIL = "TABLES_CLOSE_FAIL";

export const HOSTESS_UPDATE_TABLE_REQUEST = "HOSTESS_UPDATE_TABLE_REQUEST";
export const HOSTESS_UPDATE_TABLE_SUCCESS = "HOSTESS_UPDATE_TABLE_SUCCESS";
export const HOSTESS_UPDATE_TABLE_FAIL = "HOSTESS_UPDATE_TABLE_FAIL";
